@import url('https://fonts.googleapis.com/css2?family=Teko:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Carter+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kumar+One+Outline&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');

/* teal - 48E1CB */
/* yellow - ffcc00 */

:root {
  --PRIMARY_COLOR: #ffcc00;
  --GREY: #292826;
}

.App {
  text-align: center;
}

#navbar{
  flex-direction: column;
  
}

/* Remove border from toggler */
.navbar-toggler {
  border: 0 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 0 !important;
}

/* Lines of the Toggler */
.toggler-icon{
  height: 3px;
  background-color: var(--PRIMARY_COLOR);
  transition: all 0.2s;
}

/* Adds Space between the lines */

.middle-bar{
  margin-top: 5px;
  margin-bottom: 5px;
}


/* State when navbar is opened (START) */
.navbar-toggler .top-bar {
  transform: rotate(45deg);
  transform-origin: 10% 10%;
  width: 30px;
}

.navbar-toggler .middle-bar {
  opacity: 0;
  filter: alpha(opacity=0);
}

.navbar-toggler .bottom-bar {
  transform: rotate(-45deg);
  transform-origin: 10% 90%;
  width: 30px;
}
/* State when navbar is opened (END) */

/* State when navbar is collapsed (START) */
.navbar-toggler.collapsed .top-bar {
  transform: rotate(0);
  width: 30px;
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
  filter: alpha(opacity=100);
  width: 18px;
}

.navbar-toggler.collapsed .bottom-bar {
  transform: rotate(0);
  width: 10px;
}
/* State when navbar is collapsed (END) */

/* Color of Toggler when collapsed */
.navbar-toggler.collapsed .toggler-icon {
  background-color: var(--PRIMARY_COLOR);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* nav elements underline on hover - START */
.nav-item-span{
  position: relative;
}

.nav-item-span::after{
  content: '';
  position: absolute;
  height: 3px;
  width: 0;
  left: 0;
  bottom: -2px;
  background-color: var(--PRIMARY_COLOR);
  transition: 250ms;
}

.nav-item-span:hover::after{
  width: 100%;
}
/* nav elements underline on hover - END */

/* join button transiotion code - START */
.join-button{
  position: relative;
  display: inline-block;
  border: 2px solid var(--PRIMARY_COLOR);
  background-color: transparent;
  font-family: "montserrat",sans-serif;
  font-weight: 300;
  color: white;
  transition: color 1s linear;
}

.join-button::before{
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: -2px;
  left: -2px;
  background-color: var(--PRIMARY_COLOR);
  z-index: -1;
  transition: transform 250ms ease;
  transform: scaleX(0);
  transform-origin: left;
}

.join-button:hover::before,
.join-button:focus::before{
  transform: scaleX(100%);
}

.join-button{
  transition: color 100ms ease;
  z-index: 0;
}

.join-button:hover,
.join-button:focus{
  color: black;
}
/* join button transiotion code - END */

/* button.shadowed,
input.shadowed:focus {
  box-shadow: 8px 8px 10px black;
} */

/* button.shadowed:active {
  box-shadow: none;
} */

/* div.shadowed{
  box-shadow: 10px 10px 20px black;
} */

.border-coners {
  background:
    linear-gradient(to right, var(--PRIMARY_COLOR) 4px, transparent 4px) 0 0,
    linear-gradient(to right, var(--PRIMARY_COLOR) 4px, transparent 4px) 0 100%,
    linear-gradient(to left, var(--PRIMARY_COLOR) 4px, transparent 4px) 100% 0,
    linear-gradient(to left, var(--PRIMARY_COLOR) 4px, transparent 4px) 100% 100%,
    linear-gradient(to bottom, var(--PRIMARY_COLOR) 4px, transparent 4px) 0 0,
    linear-gradient(to bottom, var(--PRIMARY_COLOR) 4px, transparent 4px) 100% 0,
    linear-gradient(to top, var(--PRIMARY_COLOR) 4px, transparent 4px) 0 100%,
    linear-gradient(to top, var(--PRIMARY_COLOR) 4px, transparent 4px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 50px 50px;
           }

div.features-section{
  height: 90vh;
}

@media only screen and (max-width: 768px){
  div.features-section{
    height: auto;
    width: auto;
  }
}

/* Featues Section - START */
.features{
  position: relative;
  background-color: var(--PRIMARY_COLOR);
  overflow-y: hidden;
}

.feature-item{
  text-align: center;
  color: #ffcc00;
  max-width: 45%;
  margin: 20px;
  padding: 5PX;
}

.feature-icon-img{
  height: 60px;
  width: 60px;
  padding: 5px;
}

.feature-icon{
  background-color: black;
  border-radius: 50%;
  position: absolute;
  left: calc(50% - 27px);
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.feature-content{
  border-radius: 15px;
  background-color: black;
  margin-right: 20px;
  margin-left: 20px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.feature-item:nth-child(odd){
  margin-right: calc(50% + 20px);
}

.feature-item:nth-child(even){
  margin-left: calc(50% + 45px);
}

.feature-items::before{
  content: '';
  position: absolute;
  width: 3px;
  height: 100%;
  background-color: black;
  left: calc(50% + 2px);
}

@media(max-width: 767px){
  .feature-item:nth-child(odd){
    margin: 0;
  }
  .feature-content:nth-child(even){
    margin: 0;
  }
  .feature-items::before{
    left: calc(10%);
  }
  .feature-icon{
    left: calc(6%);
  }
  .feature-content{
    flex-grow: 0;
  }
} 

/* Featues Section - END */
/* Features - START */

.timeline-section{
  overflow: hidden;
	background-color: var(--PRIMARY_COLOR);
  height: auto;
  text-align: center;
}
.timeline-item::after,
.timeline-item::before
{
  box-sizing:content-box !important; 
}
.timeline-items{
	display: flex;
	flex-wrap: wrap;
	position: relative;
}
.timeline-items::before{
	content: '';
	position: absolute;
	width: 3px;
	height: 100%;
	background-color: black;
	left: calc(50% - 1px);
}
.timeline-item{
	margin-bottom: 40px;
	width: 100%;
	position: relative;
}
.timeline-item:last-child{
	margin-bottom: 0;
}
.timeline-item:nth-child(odd){
    padding-right: calc(50% + 30px);
}
.timeline-item:nth-child(even){
    padding-left: calc(50% + 30px);
}

.feature-icon-img{
  height: 60px;
  width: 60px;
  padding: 5px;
}

.timeline-dot{
	background-color: black;
	position: absolute;
	left: calc(50% - 30px);
	border-radius: 50%;
	top:30px;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.timeline-content{
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
  border-radius: 15px;
  background-color: black;
	padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.timeline-content h3{
	color: var(--PRIMARY_COLOR);
	margin:0 0 10px;
	text-transform: capitalize;
	font-weight: 500;
}
.timeline-content p{
  color: var(--PRIMARY_COLOR);
	font-weight: 300;
	line-height: 22px;
}

/* Features - END */

/* SINGUP - START*/

/* .form-input{
  width: 75%;
  outline: none;
  border: none;
  background-color: transparent;
  color: white;
  border-bottom: 3px solid var(--PRIMARY_COLOR);
} */

/* .form-container{
  background-color: var(--PRIMARY_COLOR);
  
} */

/* SINGUP - END*/

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* FOOTER */
.footer{
  color: var(--PRIMARY_COLOR);
  background-color: black;
}
  /* FILM STRIP - START */

  .about-section{
    background-color: var(--PRIMARY_COLOR);
    text-align: center;
  }

  .about-heading{
      text-align: center;
  }

  .film-strip-content{
      font-family: "Carter one";
      text-align-last: center;
      line-height: 2em;
      font-size: calc(1.275rem + .3vw) ;
  }

  .filmstrip{
    --background: var(--GREY);
    --size: 10px;
    text-align: center;
    background-image:
      linear-gradient(to right, var(--background) var(--size), transparent var(--size)),
      linear-gradient(to bottom, var(--background) var(--size), transparent var(--size)),
      linear-gradient(to right, var(--background) var(--size), transparent var(--size)),
      linear-gradient(to bottom, var(--background) var(--size), transparent var(--size)),
      linear-gradient(to bottom, transparent var(--size), var(--background) var(--size));
    background-size: 
            calc(var(--size) * 2.5) var(--size), 
            calc(var(--size) * 2.5) var(--size), 
            calc(var(--size) * 2.5) var(--size), 
            calc(var(--size) * 2.5) var(--size), 100% 
            calc(100% - var(--size) * 3);
    background-repeat: repeat-x;
    background-position: 0 var(--size), top left, 0 calc(100% - var(--size)), bottom left, 0 var(--size);
    padding: calc(var(--size) * 3) calc(var(--size) * 2.5);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  }
    
    
    /*   
  div {
    height: 50vh;
    width: 100%;
    color: white;
  } */
  
  /* h1 {
    margin: 0;
    padding: 0;
    line-height: 1;
  } */
/* ============================SIGN UP - START================================================== */

.signup-section{
  width: 100%;
  position: relative;
  background-color: var(--GREY);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 75vh;

}

.icons-row{
  position: relative;
  /* top: -50%;
  left: -8%; */
  width: 100%;
  display: flex;
  padding: 10px 0;
  white-space: nowrap;
  font-size: 64px;
  transform: rotate(-30deg);
}
/* var(--GREY) */
.icon{
  color: var(--GREY);
  transition: 1s;
  padding: 0 5px;
  user-select: none;
  cursor: default;
}

.icon:hover{
  transition: 0s;
  color: var(--PRIMARY_COLOR);
  filter: drop-shadow(0 0 120px var(--PRIMARY_COLOR));
}

.icons-row div{
  animation: animate 80s linear infinite;
  animation-delay: -80s;
}

.icons-row div:nth-child(2){
  animation: animate2 80s linear infinite;
  animation-delay: -40s;
}

@keyframes animate{
  0%{
    transform: translateX(100%);
  }
  100%{
    transform: translateX(-100%);
  }
}

@keyframes animate2{
  0%{
    transform: translateX(0%);
  }
  100%{
    transform: translateX(-200%);
  }
}

.icons-row:nth-child(even) div{
  animation: animate3 80s linear infinite;
  animation-delay: -80s;
}

.icons-row:nth-child(even) div:nth-child(2){
  animation: animate4 80s linear infinite;
  animation-delay: -40s;
}

@keyframes animate3{
  0%{
    transform: translateX(-100%);
  }
  100%{
    transform: translateX(100%);
  }
}

@keyframes animate4{
  0%{
    transform: translateX(200%);
  }
  100%{
    transform: translateX(0%);
  }
}
.icons-div{
  position: relative;
  z-index: 0;
}

/* =====================================CALPPER - START===================================== */

.clapper > div {
  background: repeating-linear-gradient(135deg, #111 0%, #111 10%, #fff 10%, #fff 20%);
  height: 30px;
  width: 100%;
  position: absolute;
  /* border-bottom-right-radius: 10px;
  border-top-right-radius: 10px; */
  /* left: -5px; */
  top: 0;
}

@keyframes clap {
  0%{
    transform: rotate(-20deg);
  }
  50%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(-20deg);
  }
}

@keyframes movetop{
  0%{
    position: absolute;
    top: -20px;
  }
  50%{
    position: absolute;
    top: -30px;
  }
  100%{
    position: absolute;
    top: -20px;
  }
}
/* ===
===============================================FORM - START================================================== */

/* .input-form{
  padding: 3rem;
} */

.my-input-container{
  margin: 30px 0 45px 0;
}

.my-form-input{
  background-color: transparent;
  border: none;
  border-bottom: 3px solid black;
  outline: none;
  color: black;
  font-weight: bold;
  font-size: larger;
  padding-bottom: 5px;
}


.join-button-signup-page{
  background-color: black;
  color: var(--PRIMARY_COLOR);
  border: 2px solid black;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.error-message{
  color: red;
}

/* ============================ RESPONSIVENESS ================================================== */

@media(max-width: 767px){
	.timeline-items::before{
		left: 30px;
	}
	.timeline-item:nth-child(odd){
		padding-right: 0;
	}
	.timeline-item:nth-child(odd),
	.timeline-item:nth-child(even){
		padding-left: 37px;
	}
  /* left: -60px; */
	.timeline-dot{
		left: -25px;
  }
  .signup-grid{
    box-shadow: none;
  }
  .sign-up-column{
    border-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  }

  .about-para{
    font-size: 1.25rem;
    text-align: left;
    text-align-last: left;
}

  .about-heading{
    text-align: center;
  }

.filmstrip{
  padding: calc(var(--size) * 2.5) 10px;
}

.clapper {
  margin: auto;
  position: absolute;
  bottom: 4vh;
  /* margin: 30px; */
  /* top: 30%;
  left: 35%; */
  /* width: auto;
  height: auto; */
  background-color: var(--PRIMARY_COLOR);
  z-index: 1;
  border-radius: 10px;
  /* box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px; */
}

.signup-section{
  height: 65vh;
  width: 100%;
}

/* .icon{
  transition: 0s;
  color: var(--PRIMARY_COLOR);
  filter: drop-shadow(0 0 10px var(--PRIMARY_COLOR));
} */

}

/* EXTRA LARGE DESKTOP */
@media(min-width: 1600px){
  .signup-section{
    height: 50vh;
    width: 100%;
  }
  .about-para{
    padding: 1em 5em !important;
  }
}

